import React from 'react';
import '../css/Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo">Wordle</div>
    </div>
  );
};

export default Navbar;
